import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What best describes your situation?"
    options={[
      {
        label: "I need it done really quickly",
        link: "/app/tool/uizard/"
      },
      {
        label: "I am not pressed by time",
        link: "/app/digital-product-design/lo-fi/idea-testing/"
      }
  ]}/>
)

export default Picker;
